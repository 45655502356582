import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { firebase, auth, db } from '@/firebase';
import { User } from 'firebase';

import { store } from '@/store';

export interface GuildApplicationState {
  application: any;
  applications: any[];
}

@Module({ dynamic: true, store, name: 'GuildApplications' })
class GuildApplicationModule extends VuexModule implements GuildApplicationState {
  public loading: boolean = false;

  public newApplication: any = null;
  public application: any = null;
  public applications: any[] = [];

  @Action
  async loadApplication(applicationName: string) {
    this.mutateApplication(null);
    this.mutateLoading(true);

    try {
      const application = await db
        .collection('applications')
        .doc(applicationName)
        .get()
        .then(t => t.data());

      this.mutateApplication(application);
    } catch (error) {
      console.error(error);
      alert('Something went wrong submitting your application, please try again.');
    }

    this.mutateLoading(false);
  }
  @Action
  async loadApplications() {
    this.mutateApplications([]);
    this.mutateLoading(true);

    try {
      const application = await db
        .collection('applications')
        .get()
        .then(t => t.docs)
        .then(t => t.map(o => o.data()));

      this.mutateApplications(application);
    } catch (error) {
      console.error(error);
      alert('Something went wrong submitting your application, please try again.');
    }

    this.mutateLoading(false);
  }

  @Mutation
  private mutateLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  @Mutation
  private mutateApplication(application: any) {
    this.application = application;
  }

  @Mutation
  private mutateApplications(applications: any[]) {
    this.applications = applications;
  }
}

export const guildApplicationModule = getModule(GuildApplicationModule);
