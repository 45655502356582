
import Vue from 'vue';
import Component from 'vue-class-component';
import { db, firebase } from '@/firebase';

import { guildApplicationModule } from '@/store/modules/guild-application-state';

@Component({})
export default class GuildApplication extends Vue {
  private get loading() {
    return guildApplicationModule.loading;
  }

  private get application() {
    return guildApplicationModule.application;
  }

  public created() {
    guildApplicationModule.loadApplication(this.$route.params.applicationName)
  }
};
