
import Vue from 'vue';
import { db, firebase } from '@/firebase';

export default Vue.extend({
  data: function() {
    return {
      uiState: {
        loadingData: false
      },
      applications: [] as any[]
    };
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      this.uiState.loadingData = true;

      try {
        this.applications = await db
          .collection('applications')
          .get()
          .then(t => t.docs)
          .then(t => t.map(t => t.data()));
      } catch (error) {
        console.error(error);
        alert('Something went wrong submitting your application, please try again.');
      }

      this.uiState.loadingData = false;
    }
  }
});
